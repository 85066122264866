



















































































































































































































































































































































































































































import {computed, defineComponent, reactive, ref} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import CalculationInput from '../inputs/CalculationInput.vue';
import LatexNumber from '@/tasks/components/displayers/LatexNumber.vue';
import i18n from '@/locales/composables/i18n';
import {enUOttawaLabPeerAssessment} from '@/locales/en/tasks/UOttawa/enUOttawaLabPeerAssessment';

export default defineComponent({
  name: 'UOttawaLabPeerAssessment',
  components: {
    StembleLatex,
    CalculationInput,
    LatexNumber,
  },
  mixins: [DynamicQuestionMixin()],
  setup() {
    i18n.mergeLocaleMessage('en', enUOttawaLabPeerAssessment);

    const inputs = reactive({
      numberOfPartners: null,
      language: 'en',
      partner1: {
        name: null,
        grades: {
          gradeA: null,
          gradeB: null,
          gradeC: null,
          gradeD: null,
          gradeE: null,
          gradeF: null,
          gradeG: null,
          gradeH: null,
          gradeI: null,
        },
      },
      partner2: {
        name: null,
        grades: {
          gradeA: null,
          gradeB: null,
          gradeC: null,
          gradeD: null,
          gradeE: null,
          gradeF: null,
          gradeG: null,
          gradeH: null,
          gradeI: null,
        },
      },
    });

    const numberOfPartners = computed(() => {
      if (inputs.numberOfPartners !== null) {
        return inputs.numberOfPartners;
      } else {
        return 0;
      }
    });

    const partner1Name = computed(() => {
      if (inputs.partner1.name !== null) {
        return inputs.partner1.name;
      }
      return '1.';
    });

    const partner2Name = computed(() => {
      if (inputs.partner2.name !== null) {
        return inputs.partner2.name;
      }
      return '2.';
    });

    const filterAndAggregate = (arr: (string | null)[]) =>
      arr
        .filter((val) => val !== null)
        .map(Number)
        .reduce((sum, value) => sum + value, 0)
        .toString();

    const containsAllNullOrEmptyValues = (arr: (string | null)[]) =>
      Object.values(arr).every((val) => val === null || val === '');

    const totalGradePartner1 = computed(() => {
      if (containsAllNullOrEmptyValues(Object.values(inputs.partner1.grades))) {
        return '';
      }

      return filterAndAggregate(Object.values(inputs.partner1.grades));
    });

    const totalGradePartner2 = computed(() => {
      if (containsAllNullOrEmptyValues(Object.values(inputs.partner2.grades))) {
        return '';
      }

      return filterAndAggregate(Object.values(inputs.partner2.grades));
    });

    return {
      inputs,
      totalGradePartner1,
      totalGradePartner2,
      partner1Name,
      partner2Name,
      numberOfPartners,
    };
  },
  methods: {
    getQuestionText(question: any): string {
      return this.inputs.language === 'en' ? question.en : question.fr;
    },
  },
});
